export default class Background {
    constructor(el) {        
        if (typeof window !== 'undefined' && typeof window.IntersectionObserver === 'undefined') {
            return
        }
        this.DOM = {
            el: el,
            app: document.getElementById('app')
        }
        this.defaultConfig = {
            rootMargin: '0px 0px 0px 0px',
            threshold: 0.2,
        }
        const data = JSON.parse(this.DOM.el.dataset.background)
        this.config = {...this.defaultConfig, ...data.config || {}}
        this.class = data.class || ''
        this.init()
    }

    init () {
        const observer = new IntersectionObserver(this.handleIntersection.bind(this), this.config)
        observer.observe(this.DOM.el)
    }

    updateBackground (classToAdd) {
        const curr = this.DOM.app.classList.value
        const classesToRemove = (curr.match(/bg-\S+/g) || [])
        classesToRemove && this.DOM.app.classList.remove(...classesToRemove)
        this.DOM.app.classList.add(this.class.trim())
    }

    handleIntersection (entries) {
        entries.map((entry) => {
            if (entry?.isIntersecting || entry.isVisible) {
                this.class && this.updateBackground()
            }
        })

    }
}