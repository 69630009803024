import Background from './background';
import Cursor from './cursor';
import LazyPicture from './lazyPicture';
import ViewportPlay from './viewportPlay';
import LocomotiveScroll from 'locomotive-scroll';

document.querySelectorAll('[data-background]').forEach(section => new Background(section))
new Cursor(document.getElementById('cursor'));
new LazyPicture(document.getElementById('sobre'));
new LazyPicture(document.getElementById('trabalho'));
new LazyPicture(document.getElementById('canal'));
new ViewportPlay(document.getElementById('app'));

document.getElementById('app').classList.add('is-ready')    
new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    smartphone: {
        smooth: true
    },
    tablet: {
        smooth: true
    }
})
