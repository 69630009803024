export default class ViewportPlay {
    constructor(el) {        
        if (typeof window !== 'undefined' && typeof window.IntersectionObserver === 'undefined') {
            return
        }
        this.DOM = {el: el}
        this.DOM.sections = this.DOM.el.querySelectorAll('[data-viewport-play]')
        this.defaultConfig = {
            rootMargin: '0px 0px 0px 0px',
            threshold: 0.6,
        }
        const data = (this.DOM.el.dataset.viewportConfig && JSON.parse(this.DOM.el.dataset.viewportConfig)) || {}
        this.config = {...this.defaultConfig, ...data.config || {}}
        this.init()
    }

    init () {
        this.observer = new IntersectionObserver(this.handleIntersection.bind(this), this.config)
        this.DOM.sections.forEach(el => this.observer.observe(el))
    }

    handleIntersection (entries) {
        entries.map((entry) => {
            if (entry?.isIntersecting) {
                const el = entry.target
                el && el.classList.add('play')
                this.observer?.unobserve(el)
            }
        })

    }
}