export default class LazyPicture {
    constructor(el) {        
        this.DOM = {el: el}
        if (typeof window !== 'undefined' && typeof window.IntersectionObserver === 'undefined') {
            this.load()
            return
        }
        this.DOM.pictures = this.DOM.el.querySelectorAll('[data-lazy-picture]')
        this.config = {
            rootMargin: '0px 0px 40% 0px',
            threshold: 0,
        }
        this.init()
    }

    init () {
        const observer = new IntersectionObserver(this.handleIntersection.bind(this), this.config)
        this.DOM.pictures.forEach(el => observer.observe(el))
    }

    load (el) {
        const sourceEl = el.querySelector('source')
        const imgEl = el.querySelector('img')

        if (sourceEl) {
            sourceEl.dataset.srcSet && sourceEl.setAttribute('srcset', sourceEl.dataset.srcSet)
            sourceEl.dataset.sizes && sourceEl.setAttribute('sizes', sourceEl.dataset.sizes)
        }

        if (imgEl) {
            imgEl.dataset.srcSet && imgEl.setAttribute('srcset', imgEl.dataset.srcSet)
            imgEl.dataset.sizes && imgEl.setAttribute('sizes', imgEl.dataset.sizes)
            imgEl.dataset.src && imgEl.setAttribute('src', imgEl.dataset.src)
        }

        // el.classList.add('play')
    
    }

    handleIntersection (entries, observer) {
        entries.map((entry) => {
            if (entry?.isIntersecting) {
                const el = entry.target
                el && this.load(el)
                observer.unobserve(el)
            }
        })

    }
}